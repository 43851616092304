import React, { useState } from "react";
import { Button, Box } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// import Slide from "@material-ui/core/Slide";
import { AddShoppingCart } from "@material-ui/icons";
// import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import placeholder from "../../../../assets/images/food_placeholder.png";
import axiosInstance from "../../../../core/axios";
import CurrencySymbol from "../../../common/currency/currency";
import { addItemToCartAction } from "../../../../store/slices/cart-slice";
import "./establishment-view.scss";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ProdImage = styled.div`
  width: 132px;
  height: 100px;
  background-image: url(${(props) => props.image});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;

  text-align: center;
  padding: 0 10px;
  border-radius: 10%;

  @media only screen and (max-width: 768px) {
    /* width: 100%;
    height: 72px; */
    background-size: contain;
  }
`;

const ProductShortView = (props) => {
  // const history = useHistory();
  const { index } = props;
  const dispatch = useDispatch();
  const [itemAdded, setItemAdded] = useState(false);
  // const [transition, setTransition] = React.useState(undefined);
  const { item } = props;

  const addToCart = (e, item, dispatch, itemAdded) => {
    const cartItem = {
      id: item.id,
      title: item.title,
      name: item.name,
      quantity: 1,
      price: item.price,
      orderItemPrice: item.price,
      restaurantId: item.restaurant.id,
      restaurantTitle: item.restaurant.title,
      image: null,
    };
    if (item.product_images && item.product_images.length) {
      if (item.product_images[0].formats["small"]) {
        cartItem.image = item.product_images[0].formats["small"]["url"];
      } else if (item.product_images[0].formats["thumbnail"]) {
        cartItem.image = item.product_images[0].formats["thumbnail"]["url"];
      }
    }
    dispatch(addItemToCartAction(cartItem));
    setItemAdded(true); // snackbar
  };

  const FD = (val) => {
    if (!isNaN(val)) {
      return Number.parseFloat(val).toFixed(2);
    } else {
      return val;
    }
  };

  if (!item) {
    return null;
  }
  return (
    <>
      <div className="prod-short-view">
        {item["product_images"] && item["product_images"].length ? (
          <>
            <ProdImage
              image={`${axiosInstance.baseUrl}${item["product_images"][0]["formats"]["thumbnail"]["url"]}`}
            />
          </>
        ) : (
          <ProdImage image={placeholder} />
        )}
        <div className="prod-short-view-props">
          <span>
            <strong>{item.title}</strong>
          </span>
          <span className="item-desc">{item.description}</span>
          <div className="item-desc" style={{ display: "flex" }}>
            <CurrencySymbol />
            <span>{item.price_text ? item.price_text : FD(item.price)}</span>
          </div>
          {/* <Box
            style={{ width: '100%' }}
            display={{ xs: "block", sm: "none", md: "none", lg: "none" }}
          >
            <Button
              size="small"
              variant="contained"
              style={{ margin: "10px 0 10px 0", backgroundColor: "white", color: "red" }}
              endIcon={<AddShoppingCart />}
              onClick={(e) => addToCart(e, item, dispatch, itemAdded)}
            >
              Add
            </Button>
          </Box> */}
        </div>
        {index >= 0 ? (
          <Box
            style={{ marginLeft: "0px" }}
            display={{ xs: "block", sm: "block", md: "block", lg: "block" }}
          >
            <Button
              size="small"
              variant="contained"
              style={{ margin: "15px", backgroundColor: "white", color: "red" }}
              endIcon={<AddShoppingCart />}
              onClick={(e) => addToCart(e, item, dispatch, itemAdded)}
            >
              Add
            </Button>
          </Box>
        ) : null}
        <div>
          <Snackbar
            open={itemAdded}
            autoHideDuration={1000}
            onClose={(e) => {
              setItemAdded(false);
            }}
          /* TransitionComponent={transition}
        key={transition ? transition.name : ""} */
          >
            <Alert severity="success">Item added to the cart</Alert>
          </Snackbar>
        </div>
      </div>
    </>
  );
};

export default ProductShortView;
