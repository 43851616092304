import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import {
  Paper,
  Collapse,
  Button,
  Box,
  Typography,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ReactMarkdown from "markdown-to-jsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as _ from "lodash";

import ReadMoreLess from "../../../common/read-more-less/read-more-less";
import { getRestaurantBySlug, getProductsByRestaurantId } from "../../../../services/restaurant.service";
import axiosInstance from "../../../../core/axios";
import CategorizedProductsList from "./categorized-products-list";
import RatingReadOnly from "./rating-readonly";
import LDMap from "../../../maps/map";
import "./establishment-view.scss";
import NotFound from "../../404";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 180,
  },
  container: {
    display: "flex",
  },
  paper: {
    margin: theme.spacing(1),
    padding: '2px 20px',
    borderRadius: '1rem'
  },
}));

const navigateToMap = (e, slugData) => {
  if (
    navigator.platform.indexOf("iPhone") != -1 ||
    navigator.platform.indexOf("iPod") != -1 ||
    navigator.platform.indexOf("iPad") != -1
  ) {
    if (slugData["location_url_ios"]) {
      window.open(`${slugData["location_url_ios"]}`);
    }
  } else {
    if (slugData["location_url"]) {
      window.open(`${slugData["location_url"]}`);
    }
  }
};

const openPaytm = (e) => {
  window.open("https://paytm.com/login");
};

const EstablishmentView = (props) => {
  const classes = useStyles();
  const { slug } = useParams();
  const [slugData, setSlugData] = useState(null);
  const [dataFetching, setDataFetching] = useState(false);
  const [checked, setChecked] = useState(false);
  const [groupedProducts, setGroupedProducts] = useState({});
  // const [products, setProducts] = useState([]);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  useEffect(() => {
    const fetchSlugData = async () => {
      setDataFetching(true);
      const response = await getRestaurantBySlug(slug, {
        _sort: 'products.display_order:ASC'
      });
      if (response && response.data && response.data.length) {
        setSlugData(response.data[0]);
        const productsResponse = await getProductsByRestaurantId(response.data[0].id);
        if (productsResponse && productsResponse.data && productsResponse.data.length) {
          productsResponse.data.forEach(p => {
            p.display_order = parseInt(p.display_order);
            if (p.category) {
              p.category.display_order = parseInt(p.category.display_order)
            }
          });
          const categorized = _.sortBy(productsResponse.data, ['category.display_order', 'display_order']);
          const grpProducts = _.groupBy(categorized, 'category.category_name');
          setGroupedProducts(grpProducts);
        }
        setDataFetching(false);
      }
    };
    fetchSlugData();
  }, [slug]);

  if (!slugData && dataFetching === true) {
    return (
      <div className="pb">
        <CircularProgress />
      </div>
    );
  }

  if (!slugData && dataFetching === false) {
    return <NotFound />;
  }

  return (
    <div className="est-view-container">
      <div className="est-summary">
        <div className="image">
          <img
            src={`${axiosInstance.baseUrl}${slugData["image"]["formats"]["small"]["url"]}`}
            alt=""
          />
          <div className="rating-col">
            <RatingReadOnly rating={slugData.rating} />
            <span className="timings">
              <strong>
                <ScheduleIcon />{" "}
              </strong>
              1.00pm - 1.00am
            </span>
            <Button
              onClick={(e) => navigateToMap(e, slugData)}
              size="large"
              variant="contained"
              style={{ margin: "15px", backgroundColor: "white", color: "red" }}
            >
              Take me here
            </Button>
          </div>
        </div>
      </div>
      <div className="est-props">
        <h1>{slugData.title}</h1>
        <Typography variant="subtitle1" component="span">
          {slugData.subtitle}
        </Typography>
      </div>
      {/* <div className="est-reviews"></div> */}
      {/* <div className="actions">
        <Button
            onClick={(e) => openPaytm(e)}
            size="large"
            variant="contained"
            style={{ margin: "15px", backgroundColor: "white", color: "red" }}
          >Test - Open Paytm
          </Button>
      </div> */}
      {/* <LDMap></LDMap> */}
      <div className="est-description">
        {/* <Collapse in={true} timeout="auto" unmountOnExit>
          <ReactMarkdown>
            {slugData.description_rtf ? slugData.description_rtf : ''}
          </ReactMarkdown>
        </Collapse> */}
        {slugData.description_rtf ? (
          <>
            <FormControlLabel
              control={<Switch checked={checked} onChange={handleChange} />}
              label={checked ? '⬆ Less' : '⬇ More...'}
            />
            <Collapse in={checked} collapsedSize={50}>
              <Paper elevation={4} className={classes.paper}>
                <ReactMarkdown>
                  {slugData.description_rtf ? slugData.description_rtf : ""}
                </ReactMarkdown>
              </Paper>
            </Collapse>
          </>
        ) : null}
      </div>
      <br />
      <div className="list-header">
        <h2>{"Menu "}</h2>
        <h4>{"(All prices are set by restaurants directly)"}</h4>
      </div>
      <hr />
      {groupedProducts ? (
        <div className="est-products-list">
          <CategorizedProductsList
            groupedProducts={groupedProducts}
          />
        </div>
      ) : (
        <div
          style={{ textAlign: "left", width: "100%", padding: "0 0 15px 0" }}
        >
          No listing available, Please check back sometime later.
        </div>
      )}
      <Box />
    </div>
  );
};

export default EstablishmentView;
