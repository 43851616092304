import { Button } from "@material-ui/core";
import styled from "styled-components";
import primaryImage from "../../../assets/images/jama_masjid_aerial.jpg";
import "./primary.scss";

const PrimaryImage = styled.div`
  width: 100%;
  height: 491px;
  background-image: url(${primaryImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 5px 10px 18px #cacaca;

  text-align: center;
  padding: 0 10px 0 10px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 230px;
    background-size: cover;
  }
`;

export default function Primary() {
  return (
    <>
      <PrimaryImage />
      {/* <div className={"primary-image elementToFadeInAndOut"} style={{ textAlign: "center", padding: '0 10px 0 10px' }}>
            </div> */}
      <div className="p-container">
        <div className="primary-menu">
          <div className="menu-item">
            <span>Old Delhi Food</span>
          </div>
          <div className="menu-item">
            <span>Widest Delivery Range</span>
          </div>
          <div className="menu-item">
            <span>Multi-pickups</span>
          </div>
          <div style={{ padding: "10px 20px" }}>
            <Button variant="contained" size="large" color="secondary">
              Buy Now
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
