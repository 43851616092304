import axiosInstance from "../core/axios";

function transformCartToOrder(cart, userInfo) {
  // validate..
  if (!cart) {
    return undefined;
  }
  if (!userInfo) {
    return undefined;
  }
  // continue if validated..
  const order = {
    user: userInfo.id,
    order_date: new Date(),
    sumOrderItems: cart.sumOrderItems,
    gst: 0.00,
    gstAmount: cart.gstAmount,
    sumOrderItemsIncGST: cart.sumOrderItemsIncGST,
    delivery: cart.deliveryCharges,
    total_amount: cart.grandTotal,
    status: 'CREATED',
    order_status: 'PENDING',
    payment_status: 'PENDING',
    payment_mode: cart.paymentMode,
    address: cart.address,
    fullAddress: cart.fullAddress,
    active: true,
    otp: null
  }
  const orderProducts = [];
  cart.userCartItems.forEach(product => {
    const productInOrder = {
      order_id: null,
      title: product.title,
      product: product.id,
      unit_price: product.price,
      quantity: product.quantity,
      product_subtotal: product.orderItemPrice,
      restaurantId: product.restaurantId,
      restaurantTitle: product.restaurantTitle
    }
    orderProducts.push(productInOrder);
  });
  return {
    order,
    orderProducts
  }
}

async function createOrder(order, orderProducts, cb) {
  let status = undefined;
  try {
    const respOrderCreate = await axiosInstance.post("/orders", order);
    if (respOrderCreate.status == 200) {
      orderProducts.forEach(async op => {
        op.order_id = respOrderCreate.data.id;
        await axiosInstance.post("/orderproducts", op);
      });
    }
    if (cb) {
      cb(null, true, respOrderCreate.data);
    }
  }
  catch (error) {
    if (cb) {
      cb(error, false, null);
    }
  }
}

function getOrderSettings() {
  return axiosInstance.get(`/appsettings`);
}

function getDeliveries() {
  return axiosInstance.get(`/appsettings?category=deliveries`);
}

/* function getAllPosts(group) {
  if (group) {
    return axiosInstance.get(`/posts?group=${group}`);
  } else {
    return axiosInstance.get("/posts");
  }
}

function getPostsByCategory(category) {
  return axiosInstance.get(`/posts?category=${category}`);
} */

export {
  transformCartToOrder,
  createOrder,
  getOrderSettings,
  getDeliveries
}