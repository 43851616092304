import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Instagram from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Hitcount from "../components/pages/hitcount";

const social = [
  {
    name: "Facebook",
    path: "https://www.facebook.com/111392784368447",
    icon: FacebookIcon,
  },
  { name: "Instagram", path: "http://instagram.com", icon: Instagram },
];

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://www.ladelhi.com">
        LaDelhi.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: "24px 16px",
    marginTop: "auto",
    color: "#ffffff",
    backgroundColor: "#000000",
  },
}));

function Footer(props) {
  const classes = useStyles();
  const { description, title } = props;

  return (
    <footer className={classes.footer}>
      <Container>
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle1" align="center" component="p">
          {description}
        </Typography>
        <Copyright />
        <br />
        {social.map((network) => (
          <Link
            variant="body1"
            href={network.path}
            key={network.name}
            style={{
              display: "inline-block",
              color: "white",
              marginRight: "10px",
              textDecoration: "none",
            }}
          >
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <network.icon />
              </Grid>
              <Grid item>{network.name}</Grid>
            </Grid>
          </Link>
        ))}
        <Hitcount />
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Footer;
