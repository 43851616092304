import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "100%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  q: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  a: {
    fontSize: "16px",
    fontWeight: "normal",
    fontFamily: "inherit",
    whiteSpace: "pre-wrap",
  },
  spaces: {
    marginBottom: "7px",
  },
}));

const FAQs = [
  {
    q: "What is Ladelhi.com?",
    a: "In these changing times, LaDelhi.com aspires to bring Old Delhi food to your homes across Delhi-NCR.",
  },
  {
    q: "How to order food on LaDelhi.com?",
    a: `LaDelhi.com takes orders for food through WhatsApp.

        1. Login and fill-in your details - Add items to your cart - Checkout.
        2. Place order - Send order via WhatsApp. Order would be delivered on next day between 7 to 9 P.M.(Default).But you could also whatsapp in advance for a desired date and time.
        3. Pay using Paytm payment number and share screenshot on same WhatsApp number.`,
  },
  {
    q: `What are the delivery charges?`,
    a: `Scheduled Delivery Charges - (Old Delhi - ₹100, New Delhi - ₹200, Outer Delhi - ₹250, Ghaziabad - ₹250, Noida - 250, Gurgaon - ₹350, Faridabad - ₹350)`,
  },
  {
    q: `Can I get same-day delivery?`,
    a: `Yes. Same Day Delivery - Charges may increase by ₹100 in addition to the scheduled delivery charges(Refer Q3). Therefore, it is recommended to place the order one day in advance.`,
  },
  {
    q: "How much time does LaDelhi.com take to deliver orders?",
    a: `LaDelhi.com takes orders for next day delivery by default. We schedule your orders for the next day, 6 P.M. These orders are picked up and delivered directly to your location. Orders would be delivered between 7 P.M. to 9 P.M. depending upon your location.`,
  },
  {
    q: "Can I give my order in advance?",
    a: `Yes, you can order in advance at no extra cost. Mention your delivery date and preferred time along with your order on WhatsApp.`,
  },
  {
    q: "Can I order from multiple restaurants in one order?",
    a: `Yes, orders from multiple restaurants could be added in a single order.`,
  },
  {
    q: "Is there any minimum amount of food to be ordered?",
    a: `No, but most of our customers make big orders to compensate delivery cost.`,
  },
  {
    q: "Does LaDelhi.com take party orders?",
    a: `Yes, LaDelhi.com takes party orders. Plan your party and leave rest on us.`,
  },
  {
    q: "Does LaDelhi.com take Catering?",
    a: `LaDelhi.com takes catering orders for every budget and taste.`,
  },
  {
    q: "I have a different query.",
    a: `LaDelhi.com would love to assist you. Leave your query on our WhatsApp or contact us.`,
  },
  {
    q: "I am a restaurant owner, how can I associate my restaurant with LaDelhi.com?",
    a: `To associate your restaurant with LaDelhi.com, you need to specialise in your cuisine. We would review your food and let you know about your eligibility.`,
  },
];

const FAQ = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <h1>FAQs</h1>
      <hr />
      <br />
      <div className={classes.root}>
        {FAQs.map((faq, index) => (
          <>
            <Accordion
              classes={{
                root: classes.spaces,
              }}
              expanded={expanded === `panel1_${index}`}
              onChange={handleChange(`panel1_${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  content: classes.noBorder,
                }}
              >
                <Typography className={classes.heading}>
                  <span className={classes.q}>Q. {faq.q}</span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <hr style={{ width: "100%" }} />
                  <pre className={classes.a}>A. {faq.a}</pre>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </>
        ))}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default FAQ;
