import React/* , { useState, useEffect } */ from "react";
/* import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom"; */
import { GoogleLoginButton, FacebookLoginButton } from "react-social-login-buttons";
import axiosInstance from "../../core/axios";
import "./sign-in.scss";

const SignIn = (props) => {

  /* const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch(); */

  return (
    <>
      <div className="social-button-container">
        {/* <GoogleLogin
          clientId="258648441649-2ro3d6n5jika9v0rrj9q16q9jp3dv6jk.apps.googleusercontent.com"
          buttonText="Login with Google"
          onSuccess={responseGoogleSuccess}
          onFailure={responseGoogleError}
          cookiePolicy={"single_host_origin"}
        /> */}
        <a href={`${axiosInstance.baseUrl}/connect/google`}>
          <GoogleLoginButton />
        </a>
        <a href={`${axiosInstance.baseUrl}/connect/facebook`}>
          <FacebookLoginButton />
        </a>
      </div>
    </>
  );
};

export default SignIn;
