import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function YTVideoPlayer(props) {
  let { video, title } = props;
  const matches = useMediaQuery("(max-width: 768px)");
  return (
    <div style={{ textAlign: "left", padding: "5px 5px" }}>
      <h2>{title}</h2>
      <center>
        <iframe
          src={`https://www.youtube.com/embed/${video}`}
          title="Associate your restaurant with ladelhi.com"
          width="100%"
          height={matches ? "200px" : "620px"}
          frameborder="0"
          allowfullscreen
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </center>
    </div>
  );
}
