import axiosInstance from "../core/axios";

function formatDateTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  const month = date.toLocaleString('default', { month: 'short' });
  return date.getDate() + " " + (month) + " " + date.getFullYear() + " " + strTime;
}

function getPrimaryMenuItems(params) {
  return axiosInstance.get(`/posts`, {
    params
  });
}

export {
  formatDateTime,
  getPrimaryMenuItems
}