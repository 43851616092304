import axios from "axios";
import axiosConfig from "./axiosConfig";

/* const { REACT_APP_BASE_URL, NODE_ENV } = process.env;
const baseUrl = REACT_APP_BASE_URL || "https://strapi.ladelhi.com"; */

let apiBaseUrl = undefined;
const { NODE_ENV } = process.env;
if (NODE_ENV == "production") {
  apiBaseUrl = "https://strapi.ladelhi.com";
} else {
  apiBaseUrl = "https://dev.strapi.ladelhi.com";
}

function setRquestHeaderToken (token) {
  if (token) {
    instance.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    instance.defaults.headers.common["Authorization"] = "";
  }
}

const instance = axios.create({
  baseURL: apiBaseUrl,
});
instance.baseUrl = apiBaseUrl;
instance.defaults.headers.post["Content-Type"] = "application/json";
axiosConfig.init(instance);

export default instance;
export { setRquestHeaderToken };
