import { LocalPhone, EmailRounded } from "@material-ui/icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import YTVideoPlayer from "../yt-video-player";
import whatsappLogo from "../../../assets/images/whatsapp-icon-256-902637335.png";

const Partnership = (props) => {
  const welcomeMessage = encodeURIComponent("");
  const matches = useMediaQuery("(max-width: 768px)");
  return (
    <div>
      <br />
      <YTVideoPlayer
        video="kgZr3GxfXoU"
        title="Work with us: Restaurants, Caterers, Home Chefs."
      />
      <br />
      <div style={{ textAlign: "left", padding: "0 10px 0 10px" }}>
        <h2>Contact Us</h2>
        <hr style={{ width: "40%" }} />
        <h2>LaDelhi.com</h2>
        <p>1205, Punjabi Phatak, Ballimaran</p>
        <p>Chandni Chowk, Delhi - 110006</p>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              height: "30px",
            }}
          >
            <span>
              <LocalPhone style={{ fill: "green" }} />
            </span>{" "}
            <a href="tel:08851039206" style={{ marginLeft: "15px" }}>
              08851039206
            </a>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              height: "30px",
            }}
          >
            <span>
              <EmailRounded style={{ fill: "green" }} />
            </span>{" "}
            <a
              href="mailto:ladelhishopdelhi@gmail.com"
              style={{ marginLeft: "15px" }}
            >
              ladelhishopdelhi@gmail.com
            </a>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              height: "30px",
            }}
          >
            <img src={whatsappLogo} style={{ height: "24px" }} alt="" />
            <a
              href={"https://wa.me/918851039206?text=" + welcomeMessage}
              style={{ marginLeft: "15px" }}
            >
              Chat
            </a>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Partnership;
