import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUserAction } from "../../../store/slices/auth";

import axiosInstance, { setRquestHeaderToken } from "../../../core/axios";

const FacebookAuthCallback = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!location) {
      return;
    }
    const { search } = location;
    console.log(" SEARCH: ", search);
    const fetchUser = async () => {
      const res = await axiosInstance.get(
        `${axiosInstance.baseUrl}/auth/facebook/callback${search}`
      );
      const response = res.data;
      if (response && response.user) {
        const userInfo = { ...response.user, jwt: response.jwt };
        setRquestHeaderToken(response.jwt);
        dispatch(loginUserAction(userInfo));
        if (!userInfo.addresses.length) {
          history.push("/pre-checkout");
        } else {
          history.push("/user-cart-main-view");
        }
      }
    };
    fetchUser();
  }, []);

  return null;
};

export default FacebookAuthCallback;
