import ProductShortView from "./product-short-view";
import "./establishment-view.scss";

const ProductsList = (props) => {
  const { items, restaurantId, restaurantTitle } = props;
  return (
    <>
      <div className="est-prodlist">
        {items && items.length
          ? items.map((item, index) => (
              <ProductShortView
                key={item.id}
                item={item}
                index={index}
              />
            ))
          : null}
      </div>
    </>
  );
};

export default ProductsList;
