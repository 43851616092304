import axiosInstance from "../core/axios";

function getUsers(params = null) {
  return axiosInstance.get("/users", {
    params: params
  });
}

function getUserByEmailId(emailId) {
  return axiosInstance.get(`/users?email=${emailId}`);
}

function addUser(user) {
  return axiosInstance.post("/users", user);
}

async function addUserIfNotExists(user) {
  try {
    const userCheckResponse = await getUserByEmailId(user.email);
    if (userCheckResponse && userCheckResponse.data && userCheckResponse.data.length) {
      return true;
    } else {
      await axiosInstance.post("/users", user);
      return true;
    }
  } catch (error) {
    console.error("User Create Error: ", error);
  }
}

async function addUInfo(ufo) {
  return axiosInstance.post("/accounts", ufo);
}

async function addAddress(address) {
  return axiosInstance.post("/addresses", address);
}

export {
  getUsers,
  getUserByEmailId,
  addUser,
  addUserIfNotExists,
  addUInfo,
  addAddress
}