const AccountPage = (props) => {
  return (
    <iv>
      <h1>Account Page</h1>
      <hr />
    </iv>
  );
};

export default AccountPage;
