import * as React from "react";
import styled from 'styled-components';
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { setRquestHeaderToken } from "../../core/axios";
import Header from "../Header";
import Footer from "../Footer";
import NotFound from "../pages/404";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Pagehits from "../pages/pagehits";
import Blog from "../Blog";
import PostViewBySlug from "../pages/posts/post-by-slug/post-by-slug";
import Playground from "../playground";
import EstablishmentView from "../pages/establishments/establishment-view/establishment-view";
import CartMainView from "../pages/cart/cart-main-view/cart-main-view";
import SignIn from "../auth/sign-in";

import GoogleAuthCallback from "./auth/google-auth-callback";
import FacebookAuthCallback from "./auth/facebook-auth-callback";
import PreCheckout from "./auth/pre-checkout";
import UserInfo from "../user/uinfo/uinfo";
import CheckoutPage from '../pages/cart/checkout/checkout';
import FAQ from "../faq/faq";
import AccountPage from '../user/account/account-page';
import Partnership from "../pages/partnership/partnership";
/* import AddressForm from "../user/uinfo/address-form"; */

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 1250px;
  margin: auto;
  @media only screen and (max-width: 768px) {
      width: 100%;
  }
`;

const useStyles = makeStyles((theme) => ({
  /* root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '1250px',
    margin: 'auto'
  }, */
  mainContentArea: {
    padding: "0 5px 0 5px",
  },
}));

const sections = [
  { title: "About Us", url: "/about-us" },
  { title: "Contact Us", url: "/contact-us" },
];

export default function Home() {
  const classes = useStyles();
  const [firstLoad, setFirstLoad] = useState(true);
  const authInfo = useSelector((state) => {
    return state.authSlice;
  });
  const token = authInfo?.userInfo?.jwt;
  setRquestHeaderToken(token);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  return (
    <Outer>
      <CssBaseline />
      {firstLoad ? <Pagehits /> : null}
      <Header title="LaDelhi.com" sections={sections} />
      <main style={{ padding: "55px 0 0 0" }}>
        <div className={classes.mainContentArea}>
          <Switch>
            <Route exact path="/" component={Blog} />
            <Route path="/sign-in" component={SignIn} />
            <Route path="/pre-checkout" component={PreCheckout} />
            <Route path="/uinfo" component={UserInfo} />
            <Route path="/checkout" component={CheckoutPage} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/view-post/:slug" component={PostViewBySlug} />
            <Route path="/outlets/:slug" component={EstablishmentView} />
            <Route path="/user-cart-main-view" component={CartMainView} />
            <Route path="/playground" component={Playground} />
            {/* <Route path="/test-form" component={AddressForm} /> */}
            <Route path="/faq" component={FAQ} />
            <Route path="/account" component={AccountPage} />
            <Route
              path="/auth/google/callback"
              component={GoogleAuthCallback}
            />
            <Route
              path="/auth-facebook/callback"
              component={FacebookAuthCallback}
            />
            <Route path="/partnership" component={Partnership} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </main>
      <Footer title="LaDelhi.com" description="" />
    </Outer>
  );
}
