import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import { Button } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ShopNowButton from "../common/buttons/shop-now-button";
import { getRestaurants } from "../../services/restaurant.service";
import { getPrimaryMenuItems } from "../../services/common.service";
import axiosInstance from "../../core/axios";
import CurrencySymbol from "../common/currency/currency";
import RatingReadOnly from "../pages/establishments/establishment-view/rating-readonly";
import YTVideoPlayer from "../pages/yt-video-player";
import TitlebarImageList from "./circular-menus";
import followUsDiscounts from '../../assets/images/follow-us-discounts.jpg';

import "./featured-posts-list.scss";

// import {
//   EmailShareButton,
//   FacebookShareButton,
//   WhatsappShareButton,
//   WhatsappIcon,
// } from "react-share";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginTop: "10px",
    marginBottom: "30px",
    border: "1px solid #000000",
    padding: "5px",
    borderRadius: "0px",
  },
  media: {
    height: "232px",
    width: "390px",
  },
  mediaMd: {
    height: "232px",
    width: "100%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  overlayCardMedia: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  overlay: {
    padding: "0px 10px",
    top: "10px",
    right: "3%",
    color: "white",
    position: "absolute",
    backgroundColor: theme.palette.secondary.main,
    fontWeight: "bold",
  },
}));

function FeaturedPostList(props) {
  // const classes = useStyles();
  const [restaurants, setRestaurants] = useState([]);
  const [circulars, setCirculars] = useState([]);
  const matches = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    const fetchRestaurants = async () => {
      const response = await getRestaurants({ _sort: "sort_order:ASC" });
      const restaurants = response.data;
      setRestaurants(restaurants);
    };
    const fetchPrimaryMenuItems = async () => {
      const response = await getPrimaryMenuItems({
        group: "circulars-1",
        _sort: "sort_order:ASC",
      });
      const circulars = response.data;
      setCirculars(circulars);
    };
    fetchRestaurants();
    fetchPrimaryMenuItems();
  }, []);

  return (
    <div
      className="f-container"
      style={{ padding: "10px 0 0 0", backgroundColor: "white" }}
    >
      <Typography
        className="tile-title"
        variant={"body"}
        component={"span"}
        style={{ color: "#000000" }}
      >
        Now, buy Old Delhi food across Delhi-NCR.
      </Typography>
      <YTVideoPlayer video={"JmG6K6vDx7M"} title="" />

      <div style={{ padding: '20px 10px' }}>
        <ul style={{ textAlign: 'left', fontSize: '1.3rem' }}>
          <li> Multi pick-ups </li>
          <li> Next day delivery (default) </li>
          <li> Same day delivery <i>(<Link to="/contact-us" style={{ textDecoration: 'underline' }}>Contact us</Link>) </i></li>
          <li> Scheduled delivery <i>(refer <Link to="/faq" style={{ textDecoration: 'underline' }}>FAQs</Link>) </i></li>
          <li> Catering  <i>(<Link to="/contact-us" style={{ textDecoration: 'underline' }}>Contact us</Link>) </i></li>
        </ul>
      </div>

      <Typography
        className="tile-title"
        variant={"body"}
        component={"span"}
        style={{
          fontFamily: "Caveat",
          fontSize: "1.6rem",
          padding: "30px 5px 20px 5px",
        }}
      >
        🛍 <u>LaDelhi - ShopDelhi</u> 🛒
      </Typography>

      <div className="round-menus-container" fullWidth>
        {circulars.map((item) => (
          <div
            key={item.id}
            className="image-cropper"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <img
              src={`${axiosInstance.baseUrl}${item?.image?.formats?.thumbnail?.url}`}
              alt={item.title}
            />
            <span>{item.title}</span>
          </div>
        ))}
      </div>
      {/* <div>
        <TitlebarImageList />
      </div> */}

      <div className="featured-posts-list">
        {restaurants.map((restaurant) => (
          <FeaturedPost key={restaurant.id} restaurant={restaurant} />
        ))}
      </div>

      <img src={followUsDiscounts} alt="" style={{ width: matches ? '80%' : '30%', height: matches ? '120px' : 'auto' }} />
    </div>
  );
}

function FeaturedPost(props) {
  const classes = useStyles();
  const { restaurant } = props;
  const matches = useMediaQuery("(max-width: 768px)");

  return (
    <Link to={`outlets/${restaurant.slug}`}>
      <Card className={classes.root}>
        {/* <CardHeader
          title={restaurant.title}
          subheader={restaurant.subtitle}
        /> */}

        <CardMedia
          className={matches ? classes.mediaMd : classes.media}
          image={`${axiosInstance.baseUrl}${restaurant["image"]["formats"]["small"]["url"]}`}
        />
        {/* <CardMedia
          className={classes.overlayCardMedia}
          image={`${axiosInstance.baseUrl}${restaurant["image"]["formats"]["small"]["url"]}`}
        /> */}
        <div className={classes.overlay}>{restaurant.title_tag}</div>

        <CardContent style={{ padding: "5px" }}>
          <div className="content-horiz">
            <div>
              <Typography
                variant="h6"
                color="#000000"
                component="h6"
                align="left"
              >
                {restaurant.title}
              </Typography>
              <RatingReadOnly rating={restaurant.rating} />
              <Typography
                variant="body1"
                color="#000000"
                component="h3"
                align="left"
              >
                <CurrencySymbol />
                {restaurant.price_text}
              </Typography>
            </div>
            <div>
              <button className="menu-btn">MENU</button>
            </div>
          </div>
        </CardContent>

        <CardActions disableSpacing>
          <Button variant="contained" color="secondary" fullWidth>
            Order Now...
          </Button>
        </CardActions>
      </Card>
    </Link>
  );
}

export default FeaturedPost;
export { FeaturedPostList };
