import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUserByEmailId } from "../../../services/user.service";

const PreCheckout = (props) => {
  const history = useHistory();

  let uinfo = useSelector((state) => {
    return state.authSlice.userInfo;
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!uinfo) {
        return;
      }
      const response = await getUserByEmailId(uinfo.email);
      const userData = response.data;
      if (!userData) {
        history.replace("/uinfo");
      } else if (userData.length && !userData.userinfo) {
        history.replace("/uinfo");
      } else {
        history.replace("/checkout");
      }
    };
    fetchUserInfo();
  });
  return null;
};

export default PreCheckout;
