import AddressForm from "./address-form";

const UserInfo = (props) => {
  return (
    <>
      <AddressForm />
    </>
  );
};

export default UserInfo;
