import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const LDMap = (props) => {
  const { center, zoom, text } = props;
  return (
    <div style={{ height: "80vh", width: "90%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBr4sH2oKeXlLi4oAMY3hTtMAYJYCihy9Q" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <AnyReactComponent lat={center.lat} lng={center.lng} text={text} />
      </GoogleMapReact>
    </div>
  );
};

LDMap.defaultProps = {
  center: {
    lat: 28.64863340652564,
    lng: 77.2337096495236,
  },
  zoom: 11,
  text: "Marker Here...",
};

export default LDMap;
