import React, { useState } from "react";
import ProductsList from "./products-list";
import "./establishment-view.scss";

const CategorizedProductsList = (props) => {
  const { groupedProducts } = props;
  const [products, setProducts] = useState([]);
  const cats = Object.keys(groupedProducts).map((category, index) => {
    return {
      id: index + 1,
      category: category,
      products: groupedProducts[category],
    };
  });
  return (
    <>
      <div className="est-prodlist">
        {cats && cats.length
          ? cats.map((category, index) => (
            <>
              <div className="cat" style={{ fontFamily: "'Delius Swash Caps', cursive", fontSize: '18px', color: 'gray' }}>
                <strong>{category.category && category.category != 'undefined' ? category.category : ''}</strong>
              </div>
              <ProductsList
                key={category.id}
                items={category.products}
              />
            </>
          ))
          : null}
      </div>
    </>
  );
};

export default CategorizedProductsList;
