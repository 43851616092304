import { combineReducers } from "redux";
import userCartSlice from "./cart-slice";
import authSlice from "./auth";

const reducers = combineReducers({
    userCartSlice,
    authSlice
});

export default reducers;
