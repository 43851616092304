import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  userInfo: {},
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState: initialState,
  reducers: {
    loginUserAction: (state, action) => {
      state.userInfo = action.payload;
      state.isLoggedIn = true;
    },
    setUserLoggedInAction: (state, action) => {
      state.userInfo = action.payload;
      state.isLoggedIn = true;
    },
    logoutUserAction: (state, action) => {
      state.userInfo = {};
      state.isLoggedIn = false;
    },
    updateUserInfoAndAddress: (state, action) => {
      state.userInfo.account = action.payload.account;
      state.userInfo.addresses.push(action.payload.address);
    },
  },
});

export const {
  loginUserAction,
  logoutUserAction,
  setUserLoggedInAction,
  updateUserInfoAndAddress,
} = authSlice.actions;
export default authSlice.reducer;
