import React from "react";
import styled from "styled-components";
import { StyledFlexboxRow } from "../styled/flex-styled-components";

const BtnDec = styled.div`
  width: 24px;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  background-color: lightgray;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  user-select: none;
`;

const Qty = styled.div`
  width: 24px;
  height: 20px;
  line-height: 20px;
  background-color: #ececec;
  user-select: none;
`;

const BtnInc = styled.div`
  width: 24px;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  background-color: lightgray;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  user-select: none;
`;

const Quantity = (props) => {
  const { item } = props;
  const [, setQuantity] = React.useState(item.quantity);

  const decrementQty = (e, item) => {
    if (item.quantity <= 1) {
      return;
    }
    setQuantity(item.quantity - 1);
    if (props.onIncrementQuantity) {
      props.onDecrementQuantity(item.quantity);
    }
  };

  const incrementQty = (e, item) => {
    setQuantity(item.quantity + 1);
    if (props.onIncrementQuantity) {
      props.onIncrementQuantity(item.quantity);
    }
  };

  return (
    <div style={{ ...props.style }}>
      <StyledFlexboxRow jc={"center"} ai={"center"}>
        <StyledFlexboxRow
          jc={"center"}
          ai={"center"}
          onClick={(e) => decrementQty(e, item)}
        >
          <BtnDec>-</BtnDec>
        </StyledFlexboxRow>
        <StyledFlexboxRow jc={"center"} ai={"center"}>
          <Qty>{item.quantity ? item.quantity : 0}</Qty>
        </StyledFlexboxRow>
        <StyledFlexboxRow
          jc={"center"}
          ai={"center"}
          onClick={(e) => incrementQty(e, item)}
        >
          <BtnInc>+</BtnInc>
        </StyledFlexboxRow>
      </StyledFlexboxRow>
    </div>
  );
};

export default Quantity;
