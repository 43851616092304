import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from './slices';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
/* import storage from 'redux-persist/lib/storage' */
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'

/* const persistConfig = {
  key: 'root',
  version: 1,
  storage,
} */
const ssl = window.location.protocol === "http:" ? false : true;
const persistConfig = {
  key: "root",
  storage: new CookieStorage(Cookies, {
    secure: ssl ? true : false,
    expires: 604800 /* 1 full week */
  })
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

let persistor = persistStore(store);
export {
  store,
  persistor
};
