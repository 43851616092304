import styled from "styled-components";

const BaseFlexbox = styled.div`
  display: flex;
  justify-content: ${(props) => (props.jc ? `${props.jc}` : "flex-start")};
  align-items: ${(props) => (props.ai ? `${props.ai}` : "flex-start")};
  width: ${(props) => (props.size / 12) * 100}vw;
  height: ${(props) => (props.height ? `${props.height}` : "")};
`;

const BaseFlexboxRow = styled(BaseFlexbox)`
  flex-direction: row;
`;

const BaseFlexboxColumn = styled(BaseFlexbox)`
  flex-direction: column;
`;

export const StyledFlexboxRow = styled(BaseFlexboxRow)``;

export const StyledFlexboxColumn = styled(BaseFlexboxColumn)``;
