import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import AppDrawer from "../components/drawer/Drawer";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import styled from "styled-components";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import CssBaseline from "@material-ui/core/CssBaseline";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "../scss/main.scss";
import { useSelector, useDispatch } from "react-redux";
import { logoutUserAction } from "../store/slices/auth";
import ld_logo from "../assets/images/ld_logo.jpg";

import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import { setRquestHeaderToken } from "../core/axios";

const WAAB = styled.div`
  text-align: left;
  position: fixed;
  width: 100px;
  height: 100px;
  right: 0vw;
  top: 85vh;
  z-index: 1000;
  div > div:first-child > div:first-child {
    /* chat window header only */
    padding: 5px;
  }
  @media only screen and (max-width: 768px) {
    right: -5vw;
  }
`;

const ElevationScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "10px",
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  menuButton: {
    color: "#e57982",
  },
  toolbarTitle: {
    fontFamily: "'Delius Swash Caps', cursive",
    textDecoration: "none",
    color: theme.palette.secondary.main,
    fontWeight: "bolder",
  },
  toolbarSecondary: {
    overflowX: "auto",
  },
  toolbarLink: {
    marginRight: "40px",
    textDecoration: "none",
    fontSize: "18px",
    color: "#000000",
  },
  nav: {
    padding: "0px",
  },
  noBorder: {
    border: "0px !important",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main
  }
}));

const Header = (props) => {
  const { title } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [drawerOpen, setOpenDrawer] = useState(false);
  const matches = useMediaQuery("(max-width: 768px)");
  const cartItemsCount = useSelector((state) => {
    const itemsCount =
      state.userCartSlice.cartInfo && state.userCartSlice.cartInfo.userCartItems
        ? state.userCartSlice.cartInfo.userCartItems.reduce(
            (count, item) => count + item.quantity,
            0
          )
        : 0;
    return itemsCount;
  });
  const authInfo = useSelector((state) => {
    return state.authSlice;
  });

  const openDrawer = () => {
    setOpenDrawer(true);
  };

  const handleSelect = () => {
    setOpenDrawer(false);
  };

  const logoutUser = (e) => {
    dispatch(logoutUserAction());
    closeUserMenu();
    setRquestHeaderToken(null);
    history.push("/sign-in");
  };

  const account = (e) => {
    history.push("/account");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeUserMenu = () => {
    setAnchorEl(null);
  };

  const navigateUserCart = (e) => {
    history.replace("/user-cart-main-view");
  };

  return (
    <div className={classes.root}>
      <AppDrawer
        anchor={"left"}
        openState={drawerOpen}
        onSelect={handleSelect}
      />
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          position="fixed"
          elevation="0"
          style={{ backgroundColor: "#fafafa" }}
        >
          <Toolbar
            color={"secondary"}
            className={classes.toolbar}
            classes={{
              root: classes.noBorder,
            }}
          >
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              onClick={openDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Typography
                component="h2"
                variant="h5"
                color="inherit"
                align="center"
                noWrap
                className={classes.toolbarTitle}
                style={{ fontSize: matches ? "24px" : "28px" }}
              >
                <div
                  style={{
                    display: "flex",
                    width: matches ? "175px" : "auto",
                    justifyContent: "space-around",
                  }}
                >
                  <img
                    src={ld_logo}
                    alt=""
                    style={{
                      height: matches ? "32px" : "38px",
                      borderRadius: "50%",
                    }}
                  />
                  {title}
                </div>
              </Typography>
            </Link>
            <div
              className="user-items"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {/* {authInfo.isLoggedIn ? (
              <Typography style={{ color: "#000000" }}>
                {authInfo.userInfo.name}
              </Typography>
            ) : null} */}
              <WAAB>
                <WhatsAppWidget
                  phoneNumber="918851039206"
                  textReplyTime="Typically replies in an hour"
                  message="Welcome to ladelhi.com"
                  companyName="Ladelhi.com"
                  sendButton="Send Message"
                />
              </WAAB>
              {true ? (
                <Tooltip
                  title={`${cartItemsCount} items in cart`}
                  placement="bottom"
                >
                  <IconButton
                    onClick={(e) => navigateUserCart(e, history)}
                    color="secondary"
                  >
                    <Badge
                      showZero
                      badgeContent={cartItemsCount}
                      color="secondary"
                      max={99}
                    >
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
              ) : null}
              {!authInfo.isLoggedIn ? (
                <Link to={{ pathname: "/sign-in", redir: location }}>
                  <Button>Login</Button>
                </Link>
              ) : null}
              {authInfo.isLoggedIn ? (
                <div>
                  <Tooltip title={authInfo.userInfo.email} placement="bottom">
                    <IconButton onClick={openUserMenu} color="secondary">
                      <Avatar
                        style={{
                          width: "24px",
                          height: "24px",
                        }}
                        classes={{
                          root: classes.avatar
                        }}
                      >
                        {authInfo.userInfo.imageUrl ? (
                          <img
                            src={`${authInfo.userInfo.imageUrl}`}
                            alt=""
                            style={{ width: "24px", height: "24px" }}
                          />
                        ) : null}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                  <Menu
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    anchorEl={anchorEl}
                    /* getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }} */
                    open={open}
                    onClose={closeUserMenu}
                  >
                    {authInfo.userInfo.imageUrl ? (
                      <>
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "140px",
                            height: "140px",
                            padding: "10px",
                          }}
                        >
                          <Avatar style={{ width: "120px", height: "120px" }}>
                            <img src={`${authInfo.userInfo.imageUrl}`} alt="" />
                          </Avatar>
                          <Typography
                            style={{
                              color: "#000000",
                              padding: "10px 0 10px 0",
                            }}
                          >
                            {authInfo.userInfo.name}
                          </Typography>
                        </div>
                        <hr />
                      </>
                    ) : null}
                    {/* <MenuItem onClick={(e) => account(e)}>Account</MenuItem> */}
                    <MenuItem onClick={(e) => logoutUser(e)}>Logout</MenuItem>
                  </Menu>
                </div>
              ) : null}
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </div>
  );
};

export default Header;
