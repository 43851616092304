import axiosInstance from "../core/axios";

function getAllPosts(group) {
  if (group) {
    return axiosInstance.get(`/posts?group=${group}`);
  } else {
    return axiosInstance.get("/posts");
  }
}

function getPostsBySlug(slug) {
  return axiosInstance.get(`/posts?slug=${slug}`);
}

function getPostsByCategory(category) {
  return axiosInstance.get(`/posts?category=${category}`);
}

export {
  getAllPosts,
  getPostsBySlug,
  getPostsByCategory
}