import axiosInstance from "../core/axios";

function pagehit(params) {
  return axiosInstance
    .post("/pagehits", params, (err, params) => { });
}

function pagehitWOParams(parameters) {
  const { origin, pathname } = parameters;
  const params = {
    path: `${origin}${pathname}`,
    user: 'admin',
    address: 'na',
    group: 'na',
    count: 1
  }
  return axiosInstance
    .post("/pagehits", params, (err, params) => { });
}

function getPageHitcount() {
  return axiosInstance
    .get("/pagehits/count");
}

export {
  pagehit,
  pagehitWOParams,
  getPageHitcount
}