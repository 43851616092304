import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  addItemToCartAction,
  reduceItemQuantityFromCartAction,
  removeItemFromCartAction,
} from "../../../../store/slices/cart-slice";

import axiosInstance from "../../../../core/axios";
import CurrencySymbol from "../../../common/currency/currency";
import {
  StyledFlexboxRow,
  StyledFlexboxColumn,
} from "../../../common/styled/flex-styled-components";
import Quantity from "../../../common/quantity/quantity";
import placeholder from '../../../../assets/images/food_placeholder.png';
import "./cart-main-view.scss";

const CartMainView = (props) => {
  // const [userCartModel, setUserCartModel] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  let userCartModel = useSelector((state) => {
    let userCart =
      state.userCartSlice && state.userCartSlice.cartInfo
        ? state.userCartSlice.cartInfo
        : undefined;
    return userCart;
  });
  let uinfo = useSelector((state) => {
    return state.authSlice.userInfo;
  });

  /* useEffect(() => {
    const loadItems = async () => {
      const tempCartModel = { ...userCart };
      setUserCartModel(tempCartModel);
    };
    loadItems();
  }, []); */

  const removeFromCart = (e, item) => {
    dispatch(removeItemFromCartAction(item));
  };

  const onDecrementQuantity = (item, qty) => {
    dispatch(reduceItemQuantityFromCartAction(item));
  };

  const onIncrementQuantity = (item, qty) => {
    dispatch(addItemToCartAction(item));
  };

  const checkout = () => {
    if (!uinfo) {
      history.push("/sign-in");
      return;
    }
    if (Object.keys(uinfo).length == 0) {
      history.push("/sign-in");
      return;
    }
    if (uinfo.addresses.length) {
      history.push("/checkout");
    } else {
      history.push("/pre-checkout");
    }
  };

  if (!userCartModel) {
    return null;
  }

  if (
    userCartModel &&
    userCartModel.userCartItems &&
    userCartModel.userCartItems.length <= 0
  ) {
    return (
      <div style={{ padding: "25px", marginTop: "25px" }}>
        <h1>Your cart is empty!</h1>
        <br />
        <Link to="/">
          <Button variant="contained" size="large" color="secondary">
            Shop Now
          </Button>
        </Link>
      </div>
    );
  }

  return (
    <>
      <div>
        <hr />
        <h2>Cart Items:</h2>
        <hr />
      </div>
      {userCartModel.userCartItems.map((item) => (
        <StyledFlexboxRow key={item.id} className="cart-item">
          <StyledFlexboxRow size={2} className="cart-item-image">
            {item["image"] ? (
              <img
                className="cart-item-image-class"
                src={`${axiosInstance.baseUrl}${item["image"]}`}
                alt=""
                style={{ borderRadius: "10%" }}
              />
            ) : (<img className="cart-item-image-class" src={placeholder} alt=""/>)}
          </StyledFlexboxRow>
          <StyledFlexboxRow size={8} className="cart-item-info">
            <span className="item-title">{item.title}</span>
            <StyledFlexboxColumn style={{ marginTop: "15px" }}>
              <StyledFlexboxRow jc={"flex-start"} ai={"center"}>
                <span>Quantity </span>
                <Quantity
                  style={{ marginLeft: "10px" }}
                  item={item}
                  onDecrementQuantity={(quantity) => {
                    onDecrementQuantity(item, quantity);
                  }}
                  onIncrementQuantity={(quantity) => {
                    onIncrementQuantity(item, quantity);
                  }}
                />
              </StyledFlexboxRow>
              {/* {item.stockStatus ? (
                <span style={{ color: "green" }}>In Stock</span>
              ) : (
                "Out of stock"
              )} */}
              <Button
                size="small"
                color="secondary"
                style={{ marginTop: "10px", marginLeft: "-5px" }}
                onClick={(e) => removeFromCart(e, item)}
              >
                Remove
              </Button>
            </StyledFlexboxColumn>
          </StyledFlexboxRow>
          <StyledFlexboxRow size={2} className="cart-item-price">
            <CurrencySymbol />{" "}
            <span>{Number.parseFloat(item.orderItemPrice).toFixed(2)}</span>
          </StyledFlexboxRow>
        </StyledFlexboxRow>
      ))}

      <StyledFlexboxColumn>
        <div className="calc-props" style={{ width: "100%" }}>
          <table style={{ tableLayout: "fixed", width: "90%" }}>
            <tbody>
              <tr>
                <td style={{ width: "70%" }}>Cart Subtotal: </td>
                <td style={{ width: "30%" }}>
                  <span>
                    {Number.parseFloat(userCartModel.sumOrderItems).toFixed(2)}
                  </span>
                </td>
              </tr>
              <tr style={{ display: "none" }}>
                <td>GST ({userCartModel.gst}%): </td>
                <td>
                  <span>
                    {Number.parseFloat(userCartModel.gstAmount).toFixed(2)}
                  </span>
                </td>
              </tr>
              <tr style={{ display: "none" }}>
                <td>Including GST: </td>
                <td>
                  <span>
                    {Number.parseFloat(
                      userCartModel.sumOrderItemsIncGST
                    ).toFixed(2)}
                  </span>
                </td>
              </tr>
              <tr style={{ display: "none" }}>
                <td>Delivery Charges: </td>
                <td>
                  <span>
                    {Number.parseFloat(userCartModel.deliveryCharges).toFixed(
                      2
                    )}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Delivery Charges: </td>
                <td>
                  <span>Charges as per delivery location</span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <hr />
                </td>
              </tr>
              <tr>
                <td>Net Total: </td>
                <td>
                  <span>
                    {Number.parseFloat(userCartModel.grandTotal).toFixed(2)}
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <hr />
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <Button
                    onClick={() => checkout()}
                    disabled={userCartModel.userCartItems.length ? false : true}
                    variant="contained"
                    size="large"
                    color="secondary"
                  >
                    {uinfo && uinfo.id ? "Checkout" : "Login to Continue"}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </StyledFlexboxColumn>
      <hr />
    </>
  );
};

export default CartMainView;
