import { useEffect, useState } from "react";
import { getPageHitcount } from "../../services/pagehits.service";

export default function Hitcount() {
  const [count, setCount] = useState(-1);
  useEffect(() => {
    const fetchCurrentHitcount = async () => {
      const response = await getPageHitcount();
      const count = await response.data;
      setCount(count);
    };
    fetchCurrentHitcount();
  }, [count]);

  return (
    <>
      <span style={{ fontSize: "12px", display: "block" }}>
        # {count === -1 ? "Fetching..." : count}
      </span>
    </>
  );
}
