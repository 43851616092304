import * as React from "react";
import { useEffect, useState } from "react";
import { FeaturedPostList } from "./featured-posts/featured-posts-list";
/* import AppCarousel from "../components/nav/carousel/carousel";
import { Card, CardContent, Typography } from "@material-ui/core"; */
import Primary from "./nav/primary/primary";

export default function Blog() {
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    setFirstLoad(true);
  }, [firstLoad]);

  return (
    <React.Fragment>
      <Primary />
      {/* <AppCarousel /> */}
      <FeaturedPostList />
    </React.Fragment>
  );
}
