import { LocalPhone, EmailRounded } from "@material-ui/icons";
import whatsappLogo from "../../assets/images/whatsapp-icon-256-902637335.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function ContactUs() {
  const welcomeMessage = encodeURIComponent("");
  const matches = useMediaQuery("(max-width: 768px)");
  return (
    <div style={{ textAlign: "left", padding: "0 10px 0 10px" }}>
      <h2>Contact Us</h2>
      <hr style={{ width: "40%" }} />
      <h2>LaDelhi.com</h2>
      <p>1205, Punjabi Phatak, Ballimaran</p>
      <p>Chandni Chowk, Delhi - 110006</p>
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            height: "30px",
          }}
        >
          <span>
            <LocalPhone style={{ fill: "green" }} />
          </span>{" "}
          <a href="tel:08851039206" style={{ marginLeft: "15px" }}>
            08851039206
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            height: "30px",
          }}
        >
          <span>
            <EmailRounded style={{ fill: "green" }} />
          </span>{" "}
          <a
            href="mailto:ladelhishopdelhi@gmail.com"
            style={{ marginLeft: "15px" }}
          >
            ladelhishopdelhi@gmail.com
          </a>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "30px",
          }}
        >
          <img src={whatsappLogo} style={{ height: "24px" }} alt="" />
          <a
            href={"https://wa.me/918851039206?text=" + welcomeMessage}
            style={{ marginLeft: "15px" }}
          >
            Chat
          </a>
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "30px",
          }}
        >
          <img src={whatsappLogo} style={{ height: "24px" }} alt="" />
          <a
            href={"https://wa.me/c/918851039206"}
            style={{ marginLeft: "15px" }}
          >
            Product Catalog
          </a>
        </div> */}
      </div>
      {/* <hr style={{ width: "100%" }} /> */}
      <br />
    </div>
  );
}
