import TextField from "@material-ui/core/TextField";
import "./ld-text-field.scss";

const LDTextField = (props) => {
  const { formik, identifier, ...rest } = props;
  return (
    <>
      <TextField
        id={identifier}
        name={identifier}
        variant="outlined"
        {...formik.getFieldProps(identifier)}
        {...rest}
      />
      {formik.touched[identifier] && formik.errors[identifier] ? (
        <div className="field-error">{formik.errors[identifier]}</div>
      ) : null}
    </>
  );
};

export default LDTextField;
