import styled from "styled-components";
import { Link } from "react-router-dom";
import notFoundImage from "../../assets/images/404-error-page.jpg";

const Template404 = styled.div`
  width: 100%;
  height: 491px;
  background-image: url(${props => props.image});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 2px 2px 2px #cacaca;

  text-align: center;
  padding: 0 10px 0 10px;
  margin-top: 5%;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 230px;
    background-size: cover;
  }
`;

export default function NotFound() {
  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      <Template404 image={notFoundImage} />
    </Link>
  );
}
