import { useEffect } from "react";
import { pagehitWOParams } from "../../services/pagehits.service";

export default function Pagehits() {
  useEffect(() => {
    const updatePagehits = async () => {
      const { origin, pathname } = window.location;
      await pagehitWOParams({ origin, pathname });
    };
    updatePagehits();
  }, []);

  return <></>;
}
