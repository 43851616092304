import { useState, useEffect } from "react";
import {
  Button,
  Box,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from "styled-components";
import { totp } from "otplib";
import * as _ from "lodash";

import {
  setPaymentModeAction,
  clearCartAction,
} from "../../../../store/slices/cart-slice";

import {
  transformCartToOrder,
  createOrder,
  getOrderSettings,
  getDeliveries,
} from "../../../../services/order.service";
import { formatDateTime } from "../../../../services/common.service";
import AddressPicker from "../address-picker/address-picker";
import axiosInstance from "../../../../core/axios";

const ItemTable = styled.div`
  width: 100%;
  padding: 5px;
  table {
    margin: auto;
    width: 50%;
    padding: 5px;
    border: 1px solid lightgray;
    tr {
      line-height: 30px;
      th {
        font-weight: bold;
        text-align: left;
        border-bottom: 1px solid gray;
      }
      td {
        text-align: left;
      }
    }
  }
`;

const CheckoutPage = (props) => {
  const dispatch = useDispatch();
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [orderDetailsSent, setOrderDetailsSent] = useState(false);
  const [tempOrder, setTempOrder] = useState(null);
  const [orderSettings, setOrderSettings] = useState(null);
  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [qrCode, setQrCode] = useState(null);
  const matches = useMediaQuery("(max-width: 768px)");

  let userCartModel = useSelector((state) => {
    let userCart =
      state.userCartSlice && state.userCartSlice.cartInfo
        ? state.userCartSlice.cartInfo
        : undefined;
    return userCart;
  });
  let uinfo = useSelector((state) => {
    return state.authSlice.userInfo;
  });

  useEffect(() => {
    const fetchOrderSettings = async () => {
      const response = await getOrderSettings();
      const settings = response.data;
      setOrderSettings(settings);
      const pcode = settings.find(s => s.category == 'payment-qr-code');
      if (pcode) {
        setQrCode(pcode.media.formats.small.url);
      }
    };
    const fetchDeliveries = async () => {
      const response = await getDeliveries();
      const deliveries = response.data;
      if (deliveries && deliveries.length) {
        setDeliveryLocations(deliveries[0]);
      }
    };
    fetchOrderSettings();
    fetchDeliveries();
  }, []);

  const getPaymentMethod = (e) => {
    dispatch(setPaymentModeAction(e.target.value));
  };

  const showBackdrop = (isOpen) => {
    setOpenBackdrop(isOpen);
  };

  const updateTempItem = (obj) => {
    setTempOrder(obj);
  };

  const generateOtp = (params) => {
    totp.options = {
      ...totp.options,
      digits: 6,
      epoch: Date.now(),
      step: 1,
    };
    const otp = totp.generate("LADELHI");
    return otp;
  };

  const placeOrder = (e) => {
    const { order, orderProducts } = transformCartToOrder(userCartModel, uinfo);
    if (!order) {
      return;
    }
    order.restaurant = orderProducts[0].restaurantId;
    order.restaurant_name = orderProducts[0].restaurantTitle;
    const otp = generateOtp();
    order.otp = otp;
    showBackdrop(true);
    createOrder(order, orderProducts, (error, success, responseData) => {
      if (success) {
        order.id = responseData.id;
        setOrderPlaced(true);
        const torder = {
          order: { ...order },
          orderProducts: [...orderProducts],
        };
        updateTempItem(torder);
        dispatch(clearCartAction(true));
        showBackdrop(false);
      } else if (error) {
        showBackdrop(false);
      }
    });
  };

  const sendOrder = (e, { order, orderProducts }) => {
    const whatsAppOrder = buildOrder(order, orderProducts);
    if (whatsAppOrder) {
      const number = getOrderContact();
      window.open(
        `https://wa.me/${number}?text=${encodeURIComponent(whatsAppOrder)}`
      );
      setOrderDetailsSent(true);
    }
  };

  const buildOrder = (order, orderProducts) => {
    if (!order) {
      return null;
    }
    const currentTime = formatDateTime(new Date());
    let items =
      "\nOrder ID: " +
      order.id +
      "\nOrder Date: " +
      currentTime +
      "\n*Takeaway Token - " +
      order.otp +
      "*\n";
    let orderProductsCopy = [...orderProducts];
    orderProductsCopy = _.groupBy(orderProductsCopy, "restaurantId");
    Object.keys(orderProductsCopy).map((key, index) => {
      items += `\n*${orderProductsCopy[key][0]["restaurantTitle"]}*\n`;
      orderProductsCopy[key].forEach((op) => {
        items += `- ${op.title} (${op.quantity}) = ${FD(
          op.product_subtotal
        )}\n`;
      });
    });
    let totals = `
Total: ${FD(order.sumOrderItems)}
Delivery: ${FD(order.delivery)}
*Amount to be paid: ${FD(order.total_amount)}*\n
*Delivery at:* ${order.fullAddress}\n`;
    let additional = `Pay to confirm your order
*<Payment mode: Paytm/UPI>*\n`;
    let waOrder = `${items}${totals}${additional}`;
    console.log(waOrder);
    return waOrder;
  };

  const FD = (val) => {
    if (!isNaN(val)) {
      return Number.parseFloat(val).toFixed(2);
    } else {
      return val;
    }
  };

  const getOrderContact = () => {
    if (orderSettings) {
      const categorySettings = orderSettings.find(
        (os) => os.category == "wa-order"
      );
      if (categorySettings) {
        return categorySettings.settings["whatsAppOrderNumber"];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const getAllOrderSettings = () => {
    let settings = {};
    if (orderSettings) {
      const categorySettings = orderSettings.find(
        (os) => os.category == "wa-order"
      );
      if (categorySettings) {
        settings = categorySettings.settings;
      }
    }
    return settings;
  };
  const paymentNumber = getAllOrderSettings().paymentNumber;

  const openPaytm = (e) => {
    window.open("https://paytm.com/login");
  };

  if (orderPlaced && tempOrder) {
    return (
      <Box mt={8}>
        <h1 style={{ color: "#e57982" }}>Order & Pay</h1>
        <hr />
        <br />
        {/* <h3>
          Pay for your order via Paytm and share screenshot. Send order via
          WhatsApp.
        </h3> */}
        {/* <br />
        <hr />
        <br />
        <h2>Order and Payment Details:</h2>
        <hr /> */}
        <span>
          Order  Here:
        </span>
        <br />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={(e) => sendOrder(e, tempOrder)}
        >
          Send Order via WhatsApp
        </Button>
        <br />
        <br />
        <br />
        <span>
          Pay On: <h3>{paymentNumber ? paymentNumber : ""}</h3>
        </span>
        <br />
        {
          qrCode ?
          (
            <img src={`${axiosInstance.baseUrl}${qrCode}`} alt="" />
          ) : null
        }
        <br />
        <Button
          onClick={(e) => openPaytm(e)}
          variant="contained"
          color="secondary"
          size="large"
        >
          Open Paytm
        </Button>
        <br />
        {/* <span>
          Whatsapp Number: <h3>{getOrderContact()}</h3>
        </span> */}
        <br />
        <br />
        <Alert severity="info">
          WhatsApp generated order and manual Paytm payment.
        </Alert>
        {orderDetailsSent ? (
          <>
            <hr />
            <br />
            <Alert severity="success" style={{ textAlign: "left" }}>
              <AlertTitle>Success</AlertTitle>
              Order dispatched to WhatsApp chat.
            </Alert>
          </>
        ) : null}
        <br />
        {/* <ItemTable>
          <table>
            <thead>
              <tr>
                <th>Item</th>
                <th style={{ textAlign: 'right' }}>Qty</th>
                <th style={{ textAlign: 'right' }}>Price</th>
              </tr>
            </thead>
            <tbody>
              {
                tempOrder.orderProducts.map(op => (
                  <tr>
                    <td><span>{op.title}</span></td>
                    <td style={{ textAlign: 'right' }}><span>{op.quantity}</span></td>
                    <td style={{ textAlign: 'right' }}><span>{op.product_subtotal}</span></td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </ItemTable> */}
        <hr />
      </Box>
    );
  }

  return (
    <Box p={4}>
      {/* <Box width="100%" mx="auto" p={2}>
        <h2 style={{ color: '#e57982' }}>Shipping</h2>
      </Box>
      <hr /> */}
      <h2>Deliver at:</h2>
      <AddressPicker
        userCartModel={userCartModel}
        uinfo={uinfo}
        deliveryLocations={deliveryLocations}
        /* onSelectAddress={(e) => onSelectAddress(e)} */
      />
      {/* <hr />
      <h2>Cart Items:</h2>
      {userCartModel.userCartItems.map((item) => (
        <StyledFlexboxRow key={item.id} className="cart-item">
          <StyledFlexboxRow size={8} className="cart-item-info">
            <span className="item-title">{item.title}</span>
            <StyledFlexboxColumn style={{ marginTop: "15px" }}>
              <StyledFlexboxRow jc={"flex-start"} ai={"center"}>
                <span>Quantity: </span>
                <span>{item.quantity}</span>&nbsp;&nbsp;
                <CurrencySymbol />{" "}
                <span>{Number.parseFloat(item.orderItemPrice).toFixed(2)}</span>
              </StyledFlexboxRow>
            </StyledFlexboxColumn>
          </StyledFlexboxRow>
        </StyledFlexboxRow>
      ))} */}
      <hr />
      {/* <h2>Payment Method</h2>
      <br />
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={matches ? "space-around" : "flex-start"}
        alignItems="center"
        style={{ textAlign: "left" }}
      >
        <Box>
          <span>Payment method:</span>
        </Box>
        <Box width="60%">
          <span style={{ textTransform: 'uppercase', fontWeight: '450' }}>{userCartModel.paymentMode}</span>
          <Select
            variant="outlined"
            labelId="address-label"
            value={userCartModel.paymentMode}
            onChange={(e) => getPaymentMethod(e)}
            style={{ width: "100%" }}
          >
            <MenuItem value={"paytm/upi"}>{"Paytm/UPI"}</MenuItem>
          </Select>
        </Box>
      </Box> */}
      <Box p={2}>
        <Button
          onClick={() => placeOrder()}
          disabled={userCartModel.userCartItems.length ? false : true}
          variant="contained"
          size="large"
          color="secondary"
        >
          Place Order
        </Button>
      </Box>
      <Backdrop style={{ zIndex: "10000", color: "#fff" }} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default CheckoutPage;
