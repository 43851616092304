import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Box, Typography, Avatar, ListItemAvatar } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { LocalPhone, PeopleAlt, Home } from "@material-ui/icons";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ExploreIcon from "@material-ui/icons/Explore";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  avatar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    color: "#e57982",
    height: "56px",
    fontWeight: "bold",
  },
  avatarHeading: {
    fontSize: "20px",
    fontFamily: "'Delius Swash Caps', cursive",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  small: {
    width: 70,
    height: 70,
  },
  large: {
    width: 100,
    // height: 100,
  },
  profileInfo: {
    display: "flex",
    justifyContent: "center",
  },
  menuLink: {
    textDecoration: "none",
    color: "#000000",
  },
  drawerRoot: {
    opacity: "0.94",
  },
  avatarRoot: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function AppDrawer({ anchor, openState, onSelect }) {
  const classes = useStyles();
  let uinfo = useSelector((state) => {
    return state.authSlice.userInfo;
  });

  const closeDrawer = () => {
    onSelect();
  };

  const ListPrimary = ({ text }) => {
    return (
      <Typography type="body2" style={{ fontSize: "14px" }}>
        {text}
      </Typography>
    );
  };

  const list = (anchor) => (
    <div className={clsx(classes.list)} onClick={closeDrawer}>
      <Divider />

      {uinfo && uinfo.account ? (
        <>
          <ListItemAvatar>
            <Box p={1} display="flex" justifyContent="center">
              <Avatar
                style={{
                  width: "120px",
                  height: "120px",
                }}
                classes={{
                  root: classes.avatarRoot
                }}
              >
                {uinfo && uinfo.account && uinfo.account.full_name
                  ? uinfo.account.full_name
                      .replace(/[^\w\s]/gi, "")
                      .split(" ")
                      .map((n) => n[0])
                      .join("")
                      .toUpperCase()
                  : ""}
              </Avatar>
            </Box>
          </ListItemAvatar>
          <Divider />
        </>
      ) : null}

      <Link to="/" className={classes.menuLink}>
        <ListItem button>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText
            className={classes.menuLink}
            primary={<ListPrimary text="Home" />}
          />
        </ListItem>
      </Link>
      {/* <ListItem button>
        <ListItemIcon>
          <ExploreIcon />
        </ListItemIcon>
        <ListItemText className={classes.menuLink} primary={<ListPrimary text="Discover" />} />
      </ListItem> */}
      <Link to="/about-us" className={classes.menuLink}>
        <ListItem button>
          <ListItemIcon>
            <PeopleAlt />
          </ListItemIcon>
          <ListItemText
            className={classes.menuLink}
            primary={<ListPrimary text="About Us" />}
          />
        </ListItem>
      </Link>
      <Link to="/contact-us" className={classes.menuLink}>
        <ListItem button>
          <ListItemIcon>
            <LocalPhone />
          </ListItemIcon>
          <ListItemText
            className={classes.menuLink}
            primary={<ListPrimary text="Contact Us" />}
          />
        </ListItem>
      </Link>
      <Link to="/partnership" className={classes.menuLink}>
        <ListItem button>
          <ListItemIcon>
            <LocalPhone />
          </ListItemIcon>
          <ListItemText
            className={classes.menuLink}
            primary={<ListPrimary text="Work with us" />}
          />
        </ListItem>
      </Link>
      <Divider />
      <Link to="/faq" className={classes.menuLink}>
        <ListItem button>
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          <ListItemText
            className={classes.menuLink}
            primary={<ListPrimary text="FAQs" />}
          />
        </ListItem>
      </Link>
    </div>
  );

  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer
          anchor={anchor}
          open={openState}
          onClose={closeDrawer}
          transitionDuration={{
            enter: 100,
            exit: 50,
          }}
          elevation={1}
          BackdropProps={{ invisible: true }}
          classes={{
            root: classes.drawerRoot,
          }}
        >
          <div className={classes.avatar}>
            <ListItem>
              <div className={classes.headingContainer}>
                <span className={classes.avatarHeading}>LaDelhi.com</span>
              </div>
            </ListItem>
          </div>
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
