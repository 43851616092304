import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    /* hidden: {
        display: "-webkit-box",
        WebkitLineClamp: 2,
        overflow: "hidden",
        WebkitBoxOrient: "vertical"
    } */
    hidden: {
        height: '60px',
        overflow: 'hidden'
    }
}));
function ReadMoreLess({ children }) {
    const classes = useStyles();
    const [isHidden, setIsHidden] = useState(true);
    return (
        <>
            <div className={isHidden ? classes.hidden : null}>{children}</div>
            <a size="small" onClick={() => setIsHidden(!isHidden)} style={{ float: 'right', marginRight: '16px', cursor: 'pointer', color: 'gray' /* position: 'absolute', right: '20px' */ }}>
                {isHidden ? "⬇ More..." : "⬆ Less"}
                {/* {isHidden ? "More..." : "Less"} */} 
            </a>
        </>
    );
}

export default ReadMoreLess;