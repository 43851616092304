import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import {
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import styled from 'styled-components';

import { getPostsBySlug } from "../../../../services/post.service";
import axiosInstance from "../../../../core/axios";

const TileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
`;
const TileItem = styled.div`
  @media screen and (max-width: 768px) {
    width: 50%;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

const useStyles = makeStyles((theme) => ({
  media: {
    height: "240px",
    width: "280px",
  },
}));

export default function PostViewBySlug(props) {
  const classes = useStyles();
  const { slug } = useParams();

  const [slugPosts, setSlugPosts] = useState([]);

  useEffect(() => {
    const fetchSlugPosts = async () => {
      const response = await getPostsBySlug(slug);
      const posts = response.data;
      setSlugPosts(posts);
    };
    fetchSlugPosts();
  }, [slug]);

  return (
    <div style={{ textAlign: "left", padding: "20px 10px 0 10px" }}>
      <TileContainer>
        {slugPosts.map((post) => (
          <TileItem
            key={post.id}
            style={{
              padding: "5px 5px",
              backgroundColor: "white",
            }}
          >
            <Card elevation={1}>
              <CardMedia
                className={classes.media}
                image={`${axiosInstance.baseUrl}${post["image"]["formats"]["small"]["url"]}`}
              />

              {/* <TileBackgroundImage image={`${axiosInstance.baseUrl}${post["image"]["formats"]["small"]["url"]}`} padding={'100%'} /> */}

              <CardContent>
                <Typography variant="body" component="h4">
                  {post.title}
                </Typography>
                <Typography variant="body" component="p">
                  {post.description}
                </Typography>
              </CardContent>
            </Card>
          </TileItem>
        ))}
      </TileContainer>
      <br />
    </div>
  );
}
