import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Box, MenuItem, Select, Paper, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setAddressAction } from "../../../../store/slices/cart-slice";

const AddressPickerHeader = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  margin: 8px;
  padding: 16px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;
const AddressHeaderSegment = styled.div`
  width: 48%;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const AddressPicker = (props) => {
  const dispatch = useDispatch();
  const { userCartModel, uinfo, deliveryLocations, deliveryCharges } = props;
  const [address, selectAddress] = useState(null);

  const onSelectAddress = (e) => {
    const id = e.id;
    const fullAddress = `
 ${e.title}
 ${e.address1}
 ${e.address2}
 ${e.landmark ? `Landmark: ${e.landmark}` : ""}
 ${e.city}, ${e.state},
 Pincode: ${e.pincode}, India
    `;
    let deliveryCharges = 0.0;
    if (deliveryLocations && deliveryLocations.settings) {
      const state = deliveryLocations.settings.states.find(
        (s) => s.stateName == e.state
      );
      if (state) {
        const city = state.cities.find((c) => c.cityName == e.city);
        if (city) {
          deliveryCharges = city.deliveryCharges;
        }
      }
    }
    dispatch(setAddressAction({ id, fullAddress, deliveryCharges }));
  };

  const getAddress = (e) => {
    const value = e.target.value;
    if (value) {
      const address = uinfo.addresses.find((a) => a.id == value);
      if (address) {
        selectAddress(address);
        if (onSelectAddress) {
          onSelectAddress(address);
        }
      } else {
        selectAddress(null);
      }
    } else {
      selectAddress(null);
    }
  };

  useEffect(() => {
    const getFirstAddress = async () => {
      if (uinfo && uinfo.addresses.length) {
        selectAddress(uinfo.addresses[0]);
        onSelectAddress(uinfo.addresses[0]);
      }
    };
    getFirstAddress();
  }, [deliveryLocations]);

  return (
    <>
      <AddressPickerHeader>
        <AddressHeaderSegment>
          <Select
            variant="outlined"
            value={address ? address.id : null}
            onChange={(e) => getAddress(e)}
            style={{ width: "100%" }}
          >
            {uinfo && uinfo.addresses.length
              ? uinfo.addresses.map((address, index) => (
                  <MenuItem key={address.id} value={address.id}>
                    {address.title}
                  </MenuItem>
                ))
              : null}
          </Select>
        </AddressHeaderSegment>
        <AddressHeaderSegment>
          {address ? (
            <Box m="2" p="4">
              <Paper
                elevation="1"
                style={{
                  margin: "16px",
                  padding: "16px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  lineHeight: "25px",
                }}
              >
                <div>{address.title}</div>
                <hr />
                <div>{address.address1}</div>
                <div>{address.address2}</div>
                {address.landmark ? (
                  <div>
                    <strong>Landmark</strong>: {address.landmark}
                  </div>
                ) : null}
                <div>
                  {address.city}, {address.state}
                </div>
                <div>
                  Pincode - {address.pincode}, {"India"}
                </div>
              </Paper>
            </Box>
          ) : (
            <div>Please select a delivery address</div>
          )}
        </AddressHeaderSegment>
        {
          userCartModel.deliveryCharges > 0 ?
          (
            <AddressHeaderSegment>
              <h3>Delivery Charges: </h3>
              <h3>{parseFloat(userCartModel.deliveryCharges).toFixed(2)}</h3>
            </AddressHeaderSegment>
          ) : (
            null
          )
        }
      </AddressPickerHeader>
    </>
  );
};

export default AddressPicker;
