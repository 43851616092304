import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartInfo: {
    sumOrderItems: 0.0,
    gst: 0.0,
    gstAmount: 0.0,
    sumOrderItemsIncGST: 0.0,
    deliveryCharges: 0.0,
    userCartItems: [],
    grandTotal: 0.0,
    address: null,
    paymentMode: "paytm/upi",
    restaurantId: null,
    restaurantTitle: null,
  },
};

export const userCartSlice = createSlice({
  name: "userCartSlice",
  initialState: initialState,
  reducers: {
    addItemToCartAction: (state, action) => {
      const existingItem = state.cartInfo.userCartItems.find(
        (i) => i.id === action.payload.id
      );
      if (existingItem) {
        existingItem.quantity += 1;
        const newPrice = existingItem.quantity * existingItem.price;
        existingItem.orderItemPrice = newPrice;
      } else {
        const newPrice = action.payload.quantity * action.payload.price;
        action.payload.orderItemPrice = newPrice;
        state.cartInfo.userCartItems.push(action.payload);
      }
      calculateTotals(state.cartInfo);
    },
    reduceItemQuantityFromCartAction: (state, action) => {
      const existingItemIndex = state.cartInfo.userCartItems.findIndex(
        (i) => i.id === action.payload.id
      );
      if (existingItemIndex > -1) {
        const item = state.cartInfo.userCartItems[existingItemIndex];
        if (item) {
          item.quantity -= 1;
          const newPrice = item.quantity * item.price;
          item.orderItemPrice = newPrice;
          calculateTotals(state.cartInfo);
        }
      }
    },
    removeItemFromCartAction: (state, action) => {
      const existingItemIndex = state.cartInfo.userCartItems.findIndex(
        (i) => i.id === action.payload.id
      );
      if (existingItemIndex > -1) {
        state.cartInfo.userCartItems.splice(existingItemIndex, 1);
        calculateTotals(state.cartInfo);
      }
    },
    setAddressAction: (state, action) => {
      state.cartInfo.address = action.payload.id;
      state.cartInfo.fullAddress = action.payload.fullAddress;
      state.cartInfo.deliveryCharges = action.payload.deliveryCharges;
      calculateTotals(state.cartInfo);
    },
    setPaymentModeAction: (state, action) => {
      state.cartInfo.paymentMode = action.payload;
    },
    clearCartAction: (state, action) => {
      state.cartInfo = { ...initialState.cartInfo };
    },
  },
});

const calculateTotals = (userCartModel) => {
  userCartModel.sumOrderItems = userCartModel.userCartItems.reduce(
    (subtotal, item) => subtotal + item.orderItemPrice,
    0
  );
  userCartModel.gstAmount =
    (userCartModel.sumOrderItems * userCartModel.gst) / 100;
  userCartModel.sumOrderItemsIncGST =
    userCartModel.sumOrderItems + userCartModel.gstAmount;
  userCartModel.grandTotal =
    userCartModel.sumOrderItemsIncGST + userCartModel.deliveryCharges;
};

export const {
  addItemToCartAction,
  reduceItemQuantityFromCartAction,
  removeItemFromCartAction,
  clearCartAction,
  setAddressAction,
  setPaymentModeAction,
} = userCartSlice.actions;
export default userCartSlice.reducer;
