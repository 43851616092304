import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Grid, Select, InputLabel, MenuItem, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import LDTextField from "../../common/fields/text-field/ld-text-field";
import { addUInfo, addAddress } from "../../../services/user.service";
import { updateUserInfoAndAddress } from "../../../store/slices/auth";
import locations from "../../../core/locations.json";
import {
  transformCartToOrder,
  createOrder,
  getOrderSettings,
  getDeliveries,
} from "../../../services/order.service";

const FormContainer = styled.div`
  width: 50%;
  padding: 15px;
  margin: auto;
  @media only screen and (max-width: 768px) {
    width: 95%;
  }
`;
const FormTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export default function AddressForm(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [deliveryLocations, setDeliveryLocations] = useState([]);

  let uinfo = useSelector((state) => {
    return state.authSlice.userInfo;
  });

  useEffect(() => {
    const fetchDeliveries = async () => {
      const response = await getDeliveries();
      const deliveries = response.data;
      if (deliveries && deliveries.length) {
        setDeliveryLocations(deliveries[0].settings.states);
      }
    };
    fetchDeliveries();
    // setStates(locations.states);
  }, []);

  const getCities = (e, formik) => {
    const selectedState = e.target.value;
    formik.setFieldValue("city", "");
    if (selectedState) {
      const state = deliveryLocations.find((l) => l.stateName == selectedState);
      if (state) {
        setCities(state.cities);
        formik.setFieldValue("state", state.stateName);
      } else {
        setCities([]);
      }
    }
  };

  const getCity = (e, formik) => {
    const selectedCity = e.target.value;
    if (selectedCity) {
      const state = deliveryLocations.find(
        (l) => l.stateName == formik.values["state"]
      );
      const city = state.cities.find((c) => c.cityName == selectedCity);
      setDeliveryCharges(city.deliveryCharges);
      formik.setFieldValue("city", city.cityName);
    } else {
      setDeliveryCharges(0);
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phone: "",
      address1: "",
      address2: "",
      landmark: "",
      city: "",
      state: "",
      pincode: "",
      country: "India",
      deliveryCharges: 0,
    },
    validationSchema: Yup.object({
      fullName: Yup.string()
        .min(3, "Must be 3 characters or more")
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      phone: Yup.string()
        .min(8, "Must be 8 characters or more")
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      address1: Yup.string()
        .min(3, "Must be 3 characters or more")
        .max(75, "Must be 75 characters or less")
        .required("Required"),
      address2: Yup.string()
        .min(3, "Must be 3 characters or more")
        .max(75, "Must be 75 characters or less")
        .required("Required"),
      city: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      pincode: Yup.string()
        .min(5, "Must be 5 characters or more")
        .max(6, "Must be 6 characters or less")
        .required("Required"),
      country: Yup.string()
        .min(3, "Must be 3 characters or more")
        .max(50, "Must be 50 characters or less")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      values.user = uinfo.id;
      values.title = values.fullName;
      values.users_permissions_user = uinfo.id;
      const data = { ...values };
      const ufo = {
        full_name: data.fullName,
        title: data.fullName,
        users: [uinfo.id],
      };
      const infoRes = await addUInfo(ufo);
      if (infoRes && infoRes.status == 200) {
        ufo.id = infoRes.data.id;
        const addressRes = await addAddress(data);
        if (addressRes && addressRes.status == 200) {
          data.id = addressRes.data.id;
          dispatch(
            updateUserInfoAndAddress({
              account: ufo,
              address: data,
              deliveryCharges: deliveryCharges,
            })
          );
          history.replace("/");
        }
      }
    },
  });
  return (
    <FormContainer>
      <FormTitle>
        Please update following information before proceeding.
      </FormTitle>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LDTextField
              formik={formik}
              identifier="fullName"
              label="Full name"
              autoComplete="la-full-name"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LDTextField
              formik={formik}
              identifier="phone"
              label="Phone number"
              autoComplete="la-phone"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LDTextField
              formik={formik}
              identifier="address1"
              label="Address line 1"
              autoComplete="la-address-line-1"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LDTextField
              formik={formik}
              identifier="address2"
              label="Address line 2"
              autoComplete="la-address-line-2"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LDTextField
              formik={formik}
              identifier="landmark"
              label="Landmark"
              autoComplete="la-landmark"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              id={"state"}
              name={"state"}
              variant="outlined"
              fullWidth
              {...formik.getFieldProps("state")}
              onChange={(e) => getCities(e, formik)}
            >
              <MenuItem value={null}>
                <em> -- Select your state -- </em>
              </MenuItem>
              {deliveryLocations && deliveryLocations.length
                ? deliveryLocations.map((state, index) => (
                    <MenuItem key={state.stateName} value={state.stateName}>
                      {state.stateName}
                    </MenuItem>
                  ))
                : null}
            </Select>
            {formik.touched["state"] && formik.errors["state"] ? (
              <div className="field-error">{formik.errors["state"]}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              id={"city"}
              name={"city"}
              variant="outlined"
              fullWidth
              {...formik.getFieldProps("city")}
              onChange={(e) => getCity(e, formik)}
            >
              <MenuItem value={null}>
                <em> -- Select your city -- </em>
              </MenuItem>
              {cities && cities.length
                ? cities.map((city, index) => (
                    <MenuItem key={city.cityName} value={city.cityName}>
                      {city.cityName}
                    </MenuItem>
                  ))
                : null}
            </Select>
            {formik.touched["city"] && formik.errors["city"] ? (
              <div className="field-error">{formik.errors["city"]}</div>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12}>
            {
              formik.values["state"] == 'Others' || formik.values["city"] == 'Others' ?
              (<div>
                <Typography style={{ color: 'red' }}>
                  <i>Contact LaDelhi to confirm your delivery location and its charges.</i>
                </Typography>
              </div>) : null
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <LDTextField
              formik={formik}
              identifier="pincode"
              label="Pincode"
              autoComplete="la-pincode"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LDTextField
              formik={formik}
              identifier="country"
              label="Country"
              autoComplete="la-country"
              required
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormContainer>
  );
}
