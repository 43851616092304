import axiosInstance from "../core/axios";

function getRestaurants(params = null) {
  return axiosInstance.get("/restaurants", {
    params: params
  });
}

function getRestaurantBySlug(slug, params = null) {
  return axiosInstance.get(`/restaurants?slug=${slug}`, {
    params: params
  });
}

function getProductsByRestaurantId(id) {
  return axiosInstance.get(`/products?restaurant.id=${id}&_sort=display_order:ASC`);
}

export {
  getRestaurants,
  getRestaurantBySlug,
  getProductsByRestaurantId
}