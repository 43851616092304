import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import axiosInstance from "../../core/axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

const itemData = [
  {
    path: "/uploads/20210809_161646_a9d011b4dc.jpg",
    title: "Item 1",
    author: "",
  },
  {
    path: "/uploads/20210809_160703_d6d71deb6e.jpg",
    title: "Item 2",
    author: "",
  },
  {
    path: "/uploads/20210809_165514_ebc95f79a0.jpg",
    title: "Item 3",
    author: "",
  },
  {
    path: "/uploads/20210809_170028_439b689ed0.jpg",
    title: "Item 4",
    author: "",
  },
  {
    path: "/uploads/20210809_161733_8c3265640f.jpg",
    title: "Item 5",
    author: "",
  },
  {
    path: "/uploads/butter_chicken_12b6cdb5e1.jpg",
    title: "Item 6",
    author: "",
  },
];

export default function TitlebarImageList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageList rowHeight={180} className={classes.imageList}>
        <ImageListItem key="Subheader" cols={2} style={{ height: "auto" }}>
          <ListSubheader component="div">December</ListSubheader>
        </ImageListItem>
        {itemData.map((item) => (
          <ImageListItem key={item.title}>
            <img
              src={`${axiosInstance.baseUrl}${item.path}`}
              alt=""
              style={{}}
            />
            <ImageListItemBar
              title={item.title}
              subtitle=""
              actionIcon={
                <IconButton
                  aria-label={`info about ${item.title}`}
                  className={classes.icon}
                >
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
}
