import useMediaQuery from "@material-ui/core/useMediaQuery";
import YTVideoPlayer from "./yt-video-player";

export default function AboutUs() {
  const matches = useMediaQuery("(max-width: 768px)");
  return (
    <div style={{ textAlign: "left", padding: "0 10px 0 10px" }}>
      <h2>About Us</h2>
      <hr style={{ width: "40%" }} />
      <h3>
        LaDelhi aspires to give a glimpse of Delhi....especially old Delhi which
        holds the grandeur of architechture,charm of markets, magnificence of
        Royals and the simplicity of a common man.
      </h3>
      <p>
        Delhi attracts tons of thousands of visitors every single day. It
        beholds something special for every visitor. For some, it is the visit
        to monuments,for others, it might be the lavish markets or just the
        finger licking food. In these changing times, we are glad to bring you
        the virtual reality of Delhi in the name of LaDelhi.
      </p>
      <br />
    </div>
  );
}
