import React from "react";
import { Box } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import "./establishment-view.scss";

const labels = {
  0.5: "Useless",
  1: "Useless+",
  1.5: "Poor",
  2: "Poor+",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

const RatingReadOnly = (props) => {
  const { rating } = props;

  const [, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);

  return (
    <div className="rating-readonly">
      <Rating
        readOnly
        name="hover-feedback"
        value={rating}
        precision={0.5}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
      />
      {/* {rating !== null && (
        <Box ml={2}>{labels[hover !== -1 ? hover : rating]}</Box>
      )} */}
    </div>
  );
};

export default RatingReadOnly;
